import { ReadFileBase64 } from '@/utils/files'
import { v4 as uuidv4 } from 'uuid'

export default {
  async insert (Vue, idtabla, id, formData) {
    for (const file of formData.files) {
      await Vue.$offline.fichero.insertSync({
        idfichero: uuidv4(),
        nombre_fichero: file.name,
        idtabla,
        data: await ReadFileBase64(file),
        id,
        idtfichero: formData.idtfichero,
        observaciones: formData.observaciones,
        carpeta: formData.carpeta,
      })
    }
  },
}
